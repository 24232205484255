export default {
    account_api: {
        id: 'account_api',
        title: 'API Счета',
    },
    account: {
        id: 'account',
        title: 'Счета',
    },
    refillbot: {
        id: 'refillbot',
        title: 'RefillBot',
    },
    card: {
        id: 'card',
        title: 'Карты',
    },
    vendor_cards: {
        id: 'vendor_cards',
        title: 'Поставщики',
    },
    employee_cards: {
        id: 'employee_cards',
        title: 'Сотрудники',
    },
    cash: {
        id: 'cash',
        title: 'Кассы',
    },
    safe: {
        id: 'safe',
        title: 'Сейф',
    },
    manager: {
        id: 'manager',
        title: 'МТЗ',
    },
    driver: {
        id: 'driver',
        title: 'Водители',
    },
    vendor: {
        id: 'vendor',
        title: 'Поставщики',
    },
    salary: {
        id: 'salary',
        title: 'Зарплаты',
    },
    installments: {
        id: 'installments',
        title: 'Рассрочка',
    },
    storage: {
        id: 'storage',
        title: 'Склады',
    },
};
